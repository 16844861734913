<template>
    <div>
        <component :is="indexComponent"></component>
    </div>
</template>

<script>
    import {
        getReport,
        getEvalId,
    } from "@/plugins/api/EnterpriseExam";
    export default {
        name: "Index",
        data() {
            return {
                indexComponent: ''
            }
        },
        components: {
            'List': () => import('@/components/science/EnterprisePhysicalExamination/List.vue'),
            'Measurement': () => import('@/components/science/EnterprisePhysicalExamination/Measurement.vue'),
            'ReportInfo': () => import('@/components/science/EnterprisePhysicalExamination/ReportInfo.vue'),
            'ScienceExamination': () => import('@/components/science/EnterprisePhysicalExamination/ScienceExamination.vue'),
            'SoftExamination': () => import('@/components/science/EnterprisePhysicalExamination/SoftExamination.vue')
        },
        mounted() {

        },
        watch: {
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to) {
                    console.log('to',to)
                    if (JSON.stringify(to.query) !== '{}') {
                        this.evalId = to.query.evalId
                        this.indexComponent = to.query.page
                    }else{
                        this.indexComponent = 'List'
                    }
                },
            }
        },
        methods: {
            async ReportIfExist() {
                let json = await getEvalId();
                if (json.code == 200) {
                    if (json.data) {
                        this.oldEvalId = json.data
                        this.evalId = json.data
                        this.getReportFun();
                    } else {
                        this.oldEvalId = ''
                        this.evalId = ''
                        this.nowIndex = 1
                        this.indexComponent = 'Measurement'
                    }

                }
            },
            // 得到报告
            async getReportFun() {
                let data = {
                    evalId: this.evalId == "" ? this.oldEvalId : this.evalId,
                };
                let json = await getReport(data);
                if (json.code == "200") {
                    this.nowIndex = 0;
                    this.indexComponent = 'ReportInfo'
                } else {
                    this.nowIndex = 1
                    this.indexComponent = 'Measurement'
                }
            },
        }
    }
</script>

<style scoped>

</style>
